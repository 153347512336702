import React from 'react';
import { Link } from 'react-router-dom';
import { Categories } from 'utils/Dictionary';

const categories = Categories.map(category => {
  return {title: uppercaseFirstLetter(category), url: `/categories/${category}`}
});

const SidebarCategories: React.FC = () => {
  return (
    <>
      <div className="z-10 border-y px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900">
        <h3>Categories</h3>
      </div>
      <ul role="list" className="divide-y divide-gray-100">
        {categories.map((category) => (
          <li key={category.title} className="flex gap-x-4 py-2 justify-center">
            <div className="min-w-0">
              <p className="text-sm leading-6 text-gray-900 truncate">
                <Link to={category.url} className="relative">{category.title || ''}</Link>
              </p>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

function uppercaseFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default SidebarCategories;
