import React from 'react';

const Footer: React.FC = () => {

  return (
    <footer className="bg-white" aria-labelledby="footer-heading">
      <div className="mt-6 border-t border-gray-900/10 pt-8 sm:mt-0 lg:mt-0 mb-6 grid grid-cols-2">
        <p className="text-xs leading-5 text-gray-500">&copy; {new Date().getFullYear()} GPTHunt</p>
        <p className="text-xs leading-5 text-gray-500 justify-self-end mr-4"><a href="https://forms.gle/BcGXUDBAB3mm5cYJ6">Contact</a></p>
      </div>
    </footer>
  );
};

export default Footer;