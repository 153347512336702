import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Main from './components/Home';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Category from 'components/Category';
import SubmitGpt from 'components/SubmitGpt';
import GPT from 'components/GPTPage';
import TopFeed from 'components/TopFeed';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const BackOffice = React.lazy(() => import('./backoffice/BackOffice'));


const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/categories/*",
    element: <Category />,
  },
  {
    path: "/submit-gpt",
    element: <SubmitGpt />,
  },
  {
    path: "/backoffice/*",
    element: <BackOffice />,
  },
  {
    path: "/gpts/*",
    element: <GPT />,
  },
  {
    path: "/top/*",
    element: <TopFeed key={Math.random()} />,
  },

]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
