import React from 'react';

type SectionHeadingProps = {
  title: string;
  children?: React.ReactNode;
}

const SectionHeading: React.FC<SectionHeadingProps> = ({ title, children }) => {

  return (
    <div className="border-b border-gray-200 pb-5">
      <h3 className="text-base font-semibold leading-6 text-gray-900">{title}</h3>
      {children && (
        <p className="mt-2 max-w-4xl text-sm text-gray-500">
          {children}
        </p>
      )}
    </div>
  );
};

export default SectionHeading;