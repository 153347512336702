import React from 'react';
import { GPTValue } from 'sg-client';
import { Link } from 'react-router-dom';
import { upvoteGPT } from 'client/SGClient';
import { ChevronDoubleUpIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import SocialShare from 'components/common/SocialShare';
import { DiscussionEmbed } from 'disqus-react';


type GPTProps = {
  gpt: GPTValue;
  isShowInModal?: boolean;
}

const GPT: React.FC<GPTProps> = ({ gpt, isShowInModal }) => {

  const [isAnimating, setIsAnimating] = React.useState(false);
  const [upvoteIncrease, setUpvoteIncrease] = React.useState(0);

  React.useEffect(() => {
    if (!isAnimating) {
      return;
    }
    // Trigger the animation by adding the class

    // Listen for the end of the animation and remove the class
    const timer = setTimeout(() => {
      setIsAnimating(false);
      setUpvoteIncrease(1);
    }, 2000); // The duration should match your CSS animation

    // Cleanup the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, [isAnimating]); // Depend on the upvotes prop to re-run the effect

  const handleUpvote = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, gpt: GPTValue | undefined) => {
    e.preventDefault();
    if (!gpt) {
      return;
    }
    if (isAnimating) {
      return;
    }
    if (upvoteIncrease != 0) {
      return;
    }

    upvoteGPT(gpt.uuid).then(() => {
      setIsAnimating(true);
    })
  }

  return (
    <>
      <div>
        <div className="grid grid-cols-1 sm:grid-cols-2 mt-4">
          <div className="justify-self-start md:ml-4 grid grid-cols-auto-2">
            <div className="h-24 w-24 grid-row-merge-2 skeleton">
              <img src={gpt.display?.profilePictureUrl} />
            </div>
            <div className="self-end ml-5 text-lg font-bold">
              <a href={`https://chat.openai.com/g/${gpt.shortUrl}`} target="_blank">{gpt.display?.name}</a>
              <a href={`https://chat.openai.com/g/${gpt.shortUrl}`} target="_blank" className="ml-2">
                <ArrowTopRightOnSquareIcon className="inline-flex items-center h-5 w-5 text-gray-500" />
              </a>
            </div>
            <div className="self-start ml-5 mt-3">
              By <span className='underline'>{gpt.author?.displayName}</span>
            </div>
          </div>
          <div className="justify-self-start mt-4 sm:mt-0 sm:justify-self-end self-center md:mr-4 grid grid-cols-auto-2" >
            <a href='' className={`mr-2`} onClick={e => handleUpvote(e, gpt)}>
              <span className="inline-flex min-h-full items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-lg font-medium text-gray-600 relative">
                <span className="flex items-center">
                  <ChevronDoubleUpIcon className="text-gray-600 h-5 w-5 md:h-10 md:w-10" />
                  <span className={`relative flex w-8 items-center justify-center`}>
                    <span className={`${isAnimating ? "animate-fade-out absolute" : ""} transition-opacity duration-300`}>{(gpt.metadata?.upvotes || 0) + upvoteIncrease}</span>
                    {isAnimating && (
                      <span className="animate-fade-in opacity-0 absolute transition-opacity duration-300">{(gpt.metadata?.upvotes || 0) + 1}</span>
                    )}
                  </span>
                </span>
              </span>
            </a>
            <div className="h-10 self-center">
              <SocialShare title={gpt.display?.name} />
            </div>
          </div>
        </div>
        <div className="mt-4 ml-4">

          <p className="mt-1 flex sm:text-base leading-5 text-gray-500 mb-4">
            <span className="relative truncate">
              {gpt.metadata?.categories?.map((category) => (
                <Link key={category} to={`/categories/${category}`} className=" mr-2 hover:underline">
                  {`#${category}`}
                </Link>
              ))}
            </span>
          </p>
          <HeaderWithText header={'Description'} text={gpt.display?.description} />
          <HeaderWithText header={'Welcome message'} text={gpt.display?.welcomeMessage} />
          <HeaderWithBulletList header={'Prompt starters'} bullets={gpt.display?.promptStarters} />
          {isShowInModal && (<HeaderWithLink header="GPT page" text="GPT page" link={`/gpts/${gpt.uuid}`} />)}
          <HeaderWithLink header="OpenAI Chat" text="OpenAI Chat" link={`https://chat.openai.com/g/${gpt.shortUrl}`} />
        </div>
        <div className='mt-4'>
          <DiscussionEmbed
            shortname='gpt'
            config={
              {
                url: 'https://gtphunt.net/gpts/' + gpt.uuid,
                identifier: gpt.uuid,
                title: gpt.display?.name,
                language: 'en_US'
              }
            }
          />
        </div>
      </div>
    </>
  );
};

function HeaderWithText({ header, text }: { header: string, text?: string }) {
  if (!text) {
    return <></>;
  }

  return (
    <div className='sm:border-b sm:border-gray-900/10 pb-12 sm:pb-4'>
      <h2 className="text-base font-semibold leading-7 text-gray-900">{header}</h2>
      <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
        {text}
      </p>
    </div>
  );
}

function HeaderWithLink({ header, text, link }: { header: string, text: string, link: string }) {
  if (!text) {
    return <></>;
  }

  return (
    <div className='sm:border-b sm:border-gray-900/10 pb-12 sm:pb-4'>
      <h2 className="text-base font-semibold leading-7 text-gray-900">{header}</h2>
      <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
        <Link className='underline' target='_blank' to={link}>{text}</Link>
      </p>
    </div>
  );
}

function HeaderWithBulletList({ header, bullets }: { header: string, bullets?: string[] }) {
  if (!bullets || !bullets.length) {
    return <></>;
  }

  return (
    <div className='sm:border-b sm:border-gray-900/10 pb-12 sm:pb-4'>
      <h2 className="text-base font-semibold leading-7 text-gray-900">{header}</h2>
      <div className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
        <ul className="list-disc pl-5">
          {bullets.map((bullet) => (<li className="mb-2" key={bullet}>{bullet}</li>))}
        </ul>
      </div>
    </div>
  );
}

export default GPT;