import { useFetchGPTsByCondition } from 'client/SGClient';
import GPTSection from 'components/gpt/GPTSection';
import SidebarFeatured from 'components/sidebar/SidebarFeatured';
import React, { useState } from 'react';
import { GPTSearchConditionValue, GPTValue, OrderByGPT } from 'sg-client';
import { Categories } from 'utils/Dictionary';

const categoriesList = Categories;

const CategoriesPage: React.FC = () => {
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [offset, setOffset] = useState(0);
  const limit = 10;

  const handleCategoriesSelected = (categories: string[]) => {
    setSelectedCategories(categories);
    setOffset(0);
    reset();
  }

  const loadMoreCallback = () => {
    setOffset(offset + limit);
  };

  const condition: GPTSearchConditionValue = {
    limit: limit,
    offset: offset,
    orderBy: {
      orderBy: [OrderByGPT.OrderByEnum.VOTESDESC]
    },
    filter: {
      categories: selectedCategories
    }
  };
  let { gpts, isLoading, reset, totalGPTs } = useFetchGPTsByCondition(condition, true);

  return <div>
    <div className='text-2xl font-bold mb-5 mt-5'>Categories</div>
    <BadgeCloud selectedCallback={handleCategoriesSelected} />
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
    </div>
    <div className="flex flex-col md:flex-row min-h-screen">
        <div className="main-content md:w-2/3 p-4">
          <GPTSection
            gpts={gpts}
            isLoading={isLoading}
            loadMoreCallback={loadMoreCallback}
            totalGPTs={totalGPTs}
          />
        </div>

        <div className="divider md:hidden w-full bg-gray-300 h-px"></div>

        <div className="divider m-4 hidden md:block md:w-px md:h-auto bg-gray-300"></div>

        <aside className="md:w-1/3 p-4 sidebar">
          <div className='md:sticky md:top-4'>
            <SidebarFeatured />
          </div>
        </aside>
      </div>
  </div>
};

type BadgeCoudProps = {
  selectedCallback?: (categories: string[]) => void;
}

const BadgeCloud = ({ selectedCallback }: BadgeCoudProps) => {
  const [categories, setCategories] = React.useState<string[]>([]);

  let categorySelected = (category: string) => {
    setCategories(prevCategories => {
      const index = prevCategories.findIndex(item => item === category);
      if (index === -1) {
        return [...prevCategories, category];
      }
      return prevCategories.filter(item => item !== category);
    });
  }

  React.useEffect(() => {
    if (selectedCallback) {
      selectedCallback(categories);
    }
  }, [categories]);

  const isCategorySelected = (category: string) => {
    return categories.findIndex(item => item === category) !== -1;
  }

  return <div className='mt-4 mb-4 w-full line-height-2_5'>
    {categoriesList.map(category => (
      <span
        key={category}
        onClick={() => categorySelected(category)}
        className={`inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs md:text-sm font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 ml-2 ${isCategorySelected(category) ? 'bg-gray-200' : ''}`}
      >
        {category}
      </span>
    ))}
  </div>
}

export default CategoriesPage;