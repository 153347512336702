import Wrapper from 'Wrapper';
import React, { useState } from 'react';
import store from "../store/Store";
import { Provider } from "react-redux";
import { useParams } from 'react-router-dom';
import SidebarCategories from './sidebar/SidebarCategories';
import { GPTSearchConditionValue, OrderByGPT } from 'sg-client';
import { useFetchGPTsByCondition } from 'client/SGClient';
import { addDays, utcDateStart, utcNextDayStart } from 'utils/Time';
import GPTSection from './gpt/GPTSection';

const TopFeed: React.FC = () => {

  const params = useParams();
  const pageType = params['*'] || '' as string;

  const today = new Date();
  const pageTypeMap = new Map<string, { title: string, description: string, searchStartDay: Date, searchEndDay: Date }>([
    ['today', {
      title: "Today's GPTs",
      description: "Explore the capabilities of today's GPT models and vote for the one that catches your eye",
      searchStartDay: utcDateStart(today),
      searchEndDay: utcNextDayStart(today),
    }],
    ['yesterday', {
      title: "Yesterday's Top GPTs",
      description: "Yesterday's selection of GPT models showcases the latest in AI advancements",
      searchStartDay: utcDateStart(addDays(today, -1)),
      searchEndDay: utcNextDayStart(addDays(today, -1)),
    }],
    ['week', {
      title: "Week's Top GPTs",
      description: "This week's selection of GPT models showcases the latest in AI advancements",
      searchStartDay: utcDateStart(addDays(today, -8)),
      searchEndDay: utcNextDayStart(addDays(today, -1)),
    }],
    ['month', {
      title: "Month's Top GPTs",
      description: "This month's selection of GPT models showcases the latest in AI advancements",
      searchStartDay: utcDateStart(addDays(today, -31)),
      searchEndDay: utcNextDayStart(addDays(today, -1)),
    }],
  ]);

  let { title, description, searchStartDay, searchEndDay } = pageTypeMap.get(pageType) || { title: "GPTs", description: "top list" };

  const [offset, setOffset] = useState(0);
  const limit = 20;
  const loadMoreCallback = () => {
    setOffset(offset + limit);
  };

  const condition: GPTSearchConditionValue = {
    limit: limit,
    offset: offset,
    orderBy: {
      orderBy: [OrderByGPT.OrderByEnum.VOTESDESC]
    },
    filter: {
      from: searchStartDay,
      to: searchEndDay
    }
  };
  let { gpts, isLoading, totalGPTs } = useFetchGPTsByCondition(condition, true);

  return (
    <Wrapper>
      <Provider store={store} >
        <div>
          <div className='text-2xl font-bold mb-5 mt-5'>{title}</div>
          <p className="mt-2 mb-4 max-w-4xl text-sm text-gray-800">{description || ''}</p>
          <div className="relative">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-300" />
            </div>
          </div>
          <div className="flex flex-col md:flex-row min-h-screen">
            <div className="main-content md:w-2/3 p-4">
              <GPTSection
                gpts={gpts}
                isLoading={isLoading}
                loadMoreCallback={loadMoreCallback}
                totalGPTs={totalGPTs}
              />
            </div>

            <div className="divider md:hidden w-full bg-gray-300 h-px"></div>

            <div className="divider m-4 hidden md:block md:w-px md:h-auto bg-gray-300"></div>

            <aside className="md:w-1/3 p-4 sidebar">
              <div className='md:sticky md:top-4'>
                <SidebarCategories />
              </div>
            </aside>
          </div>
        </div>
      </Provider>
    </Wrapper>
  );
};

export default TopFeed;