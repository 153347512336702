import Footer from 'components/common/Footer';
import GPTNavbar from 'components/navbar/GPTNavbar';
import React, { ReactElement, ReactNode } from 'react';

interface RequireType {
  children: ReactNode | ReactElement;
}

const Wrapper: React.FC<RequireType> = ({ children }: RequireType) => {

  return (
    <>
      <div className="container mx-auto">
        <GPTNavbar />
        {children}
        <Footer />
      </div>
    </>
  );
};

export default Wrapper;