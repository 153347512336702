import Wrapper from 'Wrapper';
import React from 'react';
import store from "../store/Store";
import { Provider } from "react-redux";
import CategoryFeed from './category/CategoryFeed';
import { Route, Routes, useParams } from 'react-router-dom';
import CategoriesPage from './category/CategoriesPage';

const Category: React.FC = () => {

  const params = useParams();
  const category = params['*'] || '';

  return (
    <Wrapper>
      <Provider store={store} >
        <Routes>
          <Route path=":category" element={<CategoryFeed key={Math.random()} category={category} />} />
          <Route index element={<CategoriesPage />} />
        </Routes>
      </Provider>
    </Wrapper>
  );
};

export default Category;