import { useState, useEffect, useMemo } from 'react';
import { BackofficeGptProcessTaskControllerApi, GptControllerApi, GPTSearchConditionValue, TriggerProcessGPTCommand, GPTProcessTaskSearchConditionValue, BackofficeGptControllerApi, UpdateGPTCommand, GPTValue, OrderByGPT } from 'sg-client';
import sgConfiguration from './SGConfiguration';

export function useFetchGPTs(offset?: number, limit?: number) {
  const condition: GPTSearchConditionValue = {
    limit: limit || 2,
    offset: (offset || 0)
  };

  const memoizedPromise = useMemo(() => {
    const api = new GptControllerApi(sgConfiguration);
    return api.search(condition);
  }, [offset, limit]);

  const { data, loading, error } = useWrapper(memoizedPromise);

  return {
    gpts: data?.data || [],
    isLoading: loading,
    isError: !!error
  }
}

export function useFetchGPTsByCondition(condition : GPTSearchConditionValue, append = false) {
  condition.limit = condition.limit || 1;
  condition.offset = condition.offset || 0;
  const memoizedPromise = useMemo(() => {
    const api = new GptControllerApi(sgConfiguration);
    return api.search(condition);
  }, [getHashCode(condition)]);

  const { data, loading, error, reset } = useWrapper(
    memoizedPromise, 
    append,
    (prev, response) => {
      return {
        ...response,
        data: mergeGpts(prev.data, response.data)
      };
    }
  );

  return {
    gpts: sortGpts(data?.data || [], condition.orderBy),
    isLoading: loading,
    isError: !!error,
    totalGPTs: data?.total || undefined,
    reset: reset
  }
}

function sortGpts(gpts: GPTValue[], orderByGpt?: OrderByGPT) {
  if (gpts && orderByGpt) {
    gpts.sort((a, b) => {
      if (!a.metadata || !b.metadata) {
        return 0;
      }
      if (!orderByGpt.orderBy || orderByGpt.orderBy.length === 0) {
        return 0;
      }
      if (orderByGpt.orderBy[0] === OrderByGPT.OrderByEnum.VOTESDESC) {
        return (b.metadata?.upvotes || 0) - (a.metadata?.upvotes || 0);
      } else if (orderByGpt.orderBy[0] === OrderByGPT.OrderByEnum.SHOWEDDATEDESC) {
        let now = new Date();
        return (b.metadata.showedDate || now).getTime() - (a.metadata.showedDate || now).getTime();
      }
      return 0;
    });
  }

  return gpts;
}

export function useFetchGPTTasks(authorization: string, condition: GPTProcessTaskSearchConditionValue) {
  const memoizedPromise = useMemo(() => {
    const api = new BackofficeGptProcessTaskControllerApi(sgConfiguration);
    return api.searchGPTProcessTasks(condition, authorization);
  }, [condition]);

  const { data, loading, error } = useWrapper(memoizedPromise);

  return {
    data: data || null,
    isLoading: loading,
    isError: !!error
  }
}

export function useFetchBackofficeGPTs(condition: GPTSearchConditionValue) {
  const memoizedPromise = useMemo(() => {
    const api = new BackofficeGptControllerApi(sgConfiguration);
    return api.search1(condition);
  }, [condition]);

  const { data, loading, error } = useWrapper(memoizedPromise);

  return {
    data: data || null,
    isLoading: loading,
    isError: !!error
  }
}

export function processGPTTasks(authorization: string, taskIds: string[]) {
  const command: TriggerProcessGPTCommand = {
    gptProcessTaskIds: taskIds
  }
  const api = new BackofficeGptProcessTaskControllerApi(sgConfiguration);
  return api.processGPTTasks(command, authorization);
}

export function updateGPT(authorization: string, gptId: string, command: UpdateGPTCommand) {
  const api = new BackofficeGptControllerApi(sgConfiguration);
  return api.updateGPT(command, authorization, gptId);
}

export function upvoteGPT(gptId: string) {
  const api = new GptControllerApi(sgConfiguration);
  return api.upvoteGPT(gptId);
}

export function callbackWrapper<T>(
  myPromise: Promise<T>,
  callbackResponse?: (data: T) => void,
  callbackLoading?: (loading: boolean) => void,
  callbackError?: (error: Error) => void
) {
  if (callbackLoading) {
    callbackLoading(true);
  }
  myPromise
    .then((response) => {
      if (callbackLoading) {
        callbackLoading(false);
      }
      if (callbackResponse) {
        callbackResponse(response);
      }
    })
    .catch((error) => {
      if (callbackLoading) {
        callbackLoading(false);
      }
      if (callbackError) {
        callbackError(error);
      }
    });
}

function useWrapper<T>(myPromise: Promise<T>, append = false, mergeAppendFunction?: (prev: T, response: T) => T) {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    setError(null);
    myPromise
      .then((response) => {
        setData((prev) => {
          if (prev && append && mergeAppendFunction) {
            return mergeAppendFunction(prev, response);
          } else {
            return response;
          }
        });
      })
      .catch((error) => {
        setError(error)
      })
      .finally(() => {
        setLoading(false);
      });
  }, [myPromise]);

  return {
    data: data,
    loading: loading,
    error: error,
    reset: () => {
      setData(null);
    }
  }
}

function getHashCode(obj: object): number {
  const str = JSON.stringify(obj);
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

function mergeGpts(gpts: GPTValue[], newGpts: GPTValue[]) {
  const gptMap = new Map<string, GPTValue>();
  gpts.forEach(gpt => {
    gptMap.set(gpt.id, gpt);
  });
  newGpts.forEach(gpt => {
    gptMap.set(gpt.id, gpt);
  });
  return Array.from(gptMap.values());
}
