import React, { useState } from 'react';
import store from "../store/Store";
import { Provider } from "react-redux";
import Wrapper from 'Wrapper';
import SidebarCategories from './sidebar/SidebarCategories';
import { useFetchGPTsByCondition } from 'client/SGClient';
import SectionHeading from './feed/SectionHeading';
import GPTSection from './gpt/GPTSection';
import { GPTSearchConditionValue, OrderByGPT } from 'sg-client';
import { addDays, utcNextDayStart, utcDateStart } from 'utils/Time';


const Main: React.FC = () => {

  const today = new Date();
  const todayCondition : GPTSearchConditionValue = {
    limit: 7,
    offset: 0,
    orderBy: {
      orderBy: [OrderByGPT.OrderByEnum.VOTESDESC]
    },
    filter: {
      from: utcDateStart(today),
      to: utcNextDayStart(today)
    }
  };
  let { gpts: todayGPTs, isLoading: todayIsLoading, isError: todayIsError } = useFetchGPTsByCondition(todayCondition);

  const yesterdayCondition : GPTSearchConditionValue = {
    limit: 7,
    offset: 0,
    orderBy: {
      orderBy: [OrderByGPT.OrderByEnum.VOTESDESC]
    },
    filter: {
      from: utcDateStart(addDays(today, -1)),
      to: utcNextDayStart(addDays(today, -1))
    }
  };
  let { gpts: yesterdayGPTs, isLoading: yesterdayIsLoading, isError: yesterdayIsError } = useFetchGPTsByCondition(yesterdayCondition);

  const topOfWeekCondition : GPTSearchConditionValue = {
    limit: 7,
    offset: 0,
    orderBy: {
      orderBy: [OrderByGPT.OrderByEnum.VOTESDESC]
    },
    filter: {
      from: utcDateStart(addDays(today, -8)),
      to: utcNextDayStart(addDays(today, -1))
    }
  };
  let { gpts: topOfWeekGPTs, isLoading: topOfWeekIsLoading, isError: topOfWeekIsError } = useFetchGPTsByCondition(topOfWeekCondition);

  const topOfMonthCondition : GPTSearchConditionValue = {
    limit: 7,
    offset: 0,
    orderBy: {
      orderBy: [OrderByGPT.OrderByEnum.VOTESDESC]
    },
    filter: {
      from: utcDateStart(addDays(today, -31)),
      to: utcNextDayStart(addDays(today, -1))
    }
  };
  let { gpts: topOfMonthGPTs, isLoading: topOfMonthIsLoading, isError: topOfMonthIsError } = useFetchGPTsByCondition(topOfMonthCondition);

  return (
    <Wrapper>
      <Provider store={store} >
        <div className="flex flex-col md:flex-row min-h-screen">
          <div className="main-content md:w-2/3 p-4">
            <SectionHeading title="Today's GPTs">
            Explore the capabilities of today's GPT models and vote for the one that catches your eye
            </SectionHeading>
            <GPTSection
              gpts={todayGPTs || []}
              isLoading={todayIsLoading}
              link={{href: '/top/today', title: 'See all today\'s GPTs'}}
            />

            <div className="w-full mt-10"></div>
            <SectionHeading title="Yesterday's GPTs" />
            <GPTSection
              gpts={yesterdayGPTs || []}
              isLoading={yesterdayIsLoading}
              link={{href: '/top/yesterday', title: 'See all yesterday\'s GPTs'}}
            />

            <div className="w-full mt-10"></div>
            <SectionHeading title="Top of the Week" />
            <GPTSection
              gpts={topOfWeekGPTs || []}
              isLoading={topOfWeekIsLoading}
              link={{href: '/top/week', title: 'Top of the week GPTs'}}
            />

            <div className="w-full mt-10"></div>
            <SectionHeading title="Top of the Month" />
            <GPTSection
              gpts={topOfMonthGPTs || []}
              isLoading={topOfMonthIsLoading}
              link={{href: '/top/month', title: 'Top of the month GPTs'}}
            />
          </div>

          <div className="divider md:hidden w-full bg-gray-300 h-px"></div>

          <div className="divider m-4 hidden md:block md:w-px md:h-auto bg-gray-300"></div>

          <aside className="md:w-1/3 p-4 sidebar">
            <div className='md:sticky md:top-4'>
              <SidebarCategories />
            </div>
          </aside>

        </div>
      </Provider>
    </Wrapper>
  );
}

export default Main;
