/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "http://localhost:8080".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration?: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AuthorValue
 */
export interface AuthorValue {
    /**
     * 
     * @type {string}
     * @memberof AuthorValue
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorValue
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorValue
     */
    displayName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthorValue
     */
    isAnonymous: boolean;
}
/**
 * 
 * @export
 * @interface CreateRawGPTCommand
 */
export interface CreateRawGPTCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateRawGPTCommand
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRawGPTCommand
     */
    jsonBase64: string;
}
/**
 * 
 * @export
 * @interface FilterGPT
 */
export interface FilterGPT {
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterGPT
     */
    categories?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof FilterGPT
     */
    from?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FilterGPT
     */
    to?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterGPT
     */
    uuids?: Array<string>;
}
/**
 * 
 * @export
 * @interface FilterTask
 */
export interface FilterTask {
    /**
     * 
     * @type {Date}
     * @memberof FilterTask
     */
    showedDateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FilterTask
     */
    showedDateTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof FilterTask
     */
    isNotShowed?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterTask
     */
    states?: Array<FilterTask.StatesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterTask
     */
    sources?: Array<FilterTask.SourcesEnum>;
}

/**
 * @export
 * @namespace FilterTask
 */
export namespace FilterTask {
    /**
     * @export
     * @enum {string}
     */
    export enum StatesEnum {
        CREATED = <any> 'CREATED',
        RAWGPTUPLOADED = <any> 'RAW_GPT_UPLOADED',
        GPTCREATED = <any> 'GPT_CREATED',
        DUPLICATEFOUND = <any> 'DUPLICATE_FOUND'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum SourcesEnum {
        MANUAL = <any> 'MANUAL',
        USERNOAUTH = <any> 'USER_NO_AUTH'
    }
}
/**
 * 
 * @export
 * @interface GPTDisplayValue
 */
export interface GPTDisplayValue {
    /**
     * 
     * @type {string}
     * @memberof GPTDisplayValue
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof GPTDisplayValue
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GPTDisplayValue
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof GPTDisplayValue
     */
    welcomeMessage?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GPTDisplayValue
     */
    promptStarters: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GPTDisplayValue
     */
    profilePictureUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GPTDisplayValue
     */
    categories: Array<string>;
}
/**
 * 
 * @export
 * @interface GPTMetadataValue
 */
export interface GPTMetadataValue {
    /**
     * 
     * @type {string}
     * @memberof GPTMetadataValue
     */
    uuid: string;
    /**
     * 
     * @type {Date}
     * @memberof GPTMetadataValue
     */
    showedDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof GPTMetadataValue
     */
    upvotes: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GPTMetadataValue
     */
    categories: Array<string>;
}
/**
 * 
 * @export
 * @interface GPTProcessTaskSearchConditionValue
 */
export interface GPTProcessTaskSearchConditionValue {
    /**
     * 
     * @type {number}
     * @memberof GPTProcessTaskSearchConditionValue
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof GPTProcessTaskSearchConditionValue
     */
    offset?: number;
    /**
     * 
     * @type {FilterTask}
     * @memberof GPTProcessTaskSearchConditionValue
     */
    filter?: FilterTask;
    /**
     * 
     * @type {OrderByTask}
     * @memberof GPTProcessTaskSearchConditionValue
     */
    orderBy?: OrderByTask;
}
/**
 * 
 * @export
 * @interface GPTProcessTaskValue
 */
export interface GPTProcessTaskValue {
    /**
     * 
     * @type {string}
     * @memberof GPTProcessTaskValue
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof GPTProcessTaskValue
     */
    rawGPTUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GPTProcessTaskValue
     */
    gptUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GPTProcessTaskValue
     */
    state: GPTProcessTaskValue.StateEnum;
    /**
     * 
     * @type {string}
     * @memberof GPTProcessTaskValue
     */
    source: GPTProcessTaskValue.SourceEnum;
    /**
     * 
     * @type {string}
     * @memberof GPTProcessTaskValue
     */
    userSourceUuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof GPTProcessTaskValue
     */
    createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof GPTProcessTaskValue
     */
    updatedDate: Date;
}

/**
 * @export
 * @namespace GPTProcessTaskValue
 */
export namespace GPTProcessTaskValue {
    /**
     * @export
     * @enum {string}
     */
    export enum StateEnum {
        CREATED = <any> 'CREATED',
        RAWGPTUPLOADED = <any> 'RAW_GPT_UPLOADED',
        GPTCREATED = <any> 'GPT_CREATED',
        DUPLICATEFOUND = <any> 'DUPLICATE_FOUND'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum SourceEnum {
        MANUAL = <any> 'MANUAL',
        USERNOAUTH = <any> 'USER_NO_AUTH'
    }
}
/**
 * 
 * @export
 * @interface GPTSearchConditionValue
 */
export interface GPTSearchConditionValue {
    /**
     * 
     * @type {number}
     * @memberof GPTSearchConditionValue
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof GPTSearchConditionValue
     */
    offset?: number;
    /**
     * 
     * @type {FilterGPT}
     * @memberof GPTSearchConditionValue
     */
    filter?: FilterGPT;
    /**
     * 
     * @type {OrderByGPT}
     * @memberof GPTSearchConditionValue
     */
    orderBy?: OrderByGPT;
}
/**
 * 
 * @export
 * @interface GPTValue
 */
export interface GPTValue {
    /**
     * 
     * @type {string}
     * @memberof GPTValue
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof GPTValue
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GPTValue
     */
    organizationId?: string;
    /**
     * 
     * @type {string}
     * @memberof GPTValue
     */
    shortUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof GPTValue
     */
    instructions?: string;
    /**
     * 
     * @type {AuthorValue}
     * @memberof GPTValue
     */
    author?: AuthorValue;
    /**
     * 
     * @type {GPTDisplayValue}
     * @memberof GPTValue
     */
    display?: GPTDisplayValue;
    /**
     * 
     * @type {GPTMetadataValue}
     * @memberof GPTValue
     */
    metadata?: GPTMetadataValue;
}
/**
 * 
 * @export
 * @interface OrderByGPT
 */
export interface OrderByGPT {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderByGPT
     */
    orderBy?: Array<OrderByGPT.OrderByEnum>;
}

/**
 * @export
 * @namespace OrderByGPT
 */
export namespace OrderByGPT {
    /**
     * @export
     * @enum {string}
     */
    export enum OrderByEnum {
        SHOWEDDATEDESC = <any> 'SHOWED_DATE_DESC',
        VOTESDESC = <any> 'VOTES_DESC'
    }
}
/**
 * 
 * @export
 * @interface OrderByTask
 */
export interface OrderByTask {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderByTask
     */
    orderBy?: Array<OrderByTask.OrderByEnum>;
}

/**
 * @export
 * @namespace OrderByTask
 */
export namespace OrderByTask {
    /**
     * @export
     * @enum {string}
     */
    export enum OrderByEnum {
        STATE = <any> 'STATE',
        CREATEDDATEDESC = <any> 'CREATED_DATE_DESC',
        CREATEDDATEASC = <any> 'CREATED_DATE_ASC',
        UPDATEDDATEDESC = <any> 'UPDATED_DATE_DESC',
        UPDATEDDATEASC = <any> 'UPDATED_DATE_ASC',
        SHOWEDDATEDESC = <any> 'SHOWED_DATE_DESC',
        SHOWEDDATEASC = <any> 'SHOWED_DATE_ASC'
    }
}
/**
 * 
 * @export
 * @interface PageValueGPTProcessTaskValue
 */
export interface PageValueGPTProcessTaskValue {
    /**
     * 
     * @type {Array<GPTProcessTaskValue>}
     * @memberof PageValueGPTProcessTaskValue
     */
    data: Array<GPTProcessTaskValue>;
    /**
     * 
     * @type {number}
     * @memberof PageValueGPTProcessTaskValue
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof PageValueGPTProcessTaskValue
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PageValueGPTProcessTaskValue
     */
    offset: number;
}
/**
 * 
 * @export
 * @interface PageValueGPTValue
 */
export interface PageValueGPTValue {
    /**
     * 
     * @type {Array<GPTValue>}
     * @memberof PageValueGPTValue
     */
    data: Array<GPTValue>;
    /**
     * 
     * @type {number}
     * @memberof PageValueGPTValue
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof PageValueGPTValue
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PageValueGPTValue
     */
    offset: number;
}
/**
 * 
 * @export
 * @interface PageValueRawGPTValue
 */
export interface PageValueRawGPTValue {
    /**
     * 
     * @type {Array<RawGPTValue>}
     * @memberof PageValueRawGPTValue
     */
    data: Array<RawGPTValue>;
    /**
     * 
     * @type {number}
     * @memberof PageValueRawGPTValue
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof PageValueRawGPTValue
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PageValueRawGPTValue
     */
    offset: number;
}
/**
 * 
 * @export
 * @interface RawGPTSearchCondition
 */
export interface RawGPTSearchCondition {
    /**
     * 
     * @type {Array<string>}
     * @memberof RawGPTSearchCondition
     */
    rawGPTUUIDs?: Array<string>;
}
/**
 * 
 * @export
 * @interface RawGPTValue
 */
export interface RawGPTValue {
    /**
     * 
     * @type {string}
     * @memberof RawGPTValue
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof RawGPTValue
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof RawGPTValue
     */
    data: string;
    /**
     * 
     * @type {Date}
     * @memberof RawGPTValue
     */
    createdDate: Date;
}
/**
 * 
 * @export
 * @interface TriggerProcessGPTCommand
 */
export interface TriggerProcessGPTCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof TriggerProcessGPTCommand
     */
    gptProcessTaskIds: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateGPTCommand
 */
export interface UpdateGPTCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateGPTCommand
     */
    categories?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof UpdateGPTCommand
     */
    showedDate?: Date;
}
/**
 * BackofficeGptControllerApi - fetch parameter creator
 * @export
 */
export const BackofficeGptControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GPTSearchConditionValue} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search1(body: GPTSearchConditionValue, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling search1.');
            }
            const localVarPath = `/backoffice/gpts/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GPTSearchConditionValue" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateGPTCommand} body 
         * @param {string} authorization 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGPT(body: UpdateGPTCommand, authorization: string, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateGPT.');
            }
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling updateGPT.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateGPT.');
            }
            const localVarPath = `/backoffice/gpts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateGPTCommand" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BackofficeGptControllerApi - functional programming interface
 * @export
 */
export const BackofficeGptControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GPTSearchConditionValue} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search1(body: GPTSearchConditionValue, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageValueGPTValue> {
            const localVarFetchArgs = BackofficeGptControllerApiFetchParamCreator(configuration).search1(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {UpdateGPTCommand} body 
         * @param {string} authorization 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGPT(body: UpdateGPTCommand, authorization: string, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = BackofficeGptControllerApiFetchParamCreator(configuration).updateGPT(body, authorization, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BackofficeGptControllerApi - factory interface
 * @export
 */
export const BackofficeGptControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {GPTSearchConditionValue} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search1(body: GPTSearchConditionValue, options?: any) {
            return BackofficeGptControllerApiFp(configuration).search1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {UpdateGPTCommand} body 
         * @param {string} authorization 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGPT(body: UpdateGPTCommand, authorization: string, id: string, options?: any) {
            return BackofficeGptControllerApiFp(configuration).updateGPT(body, authorization, id, options)(fetch, basePath);
        },
    };
};

/**
 * BackofficeGptControllerApi - object-oriented interface
 * @export
 * @class BackofficeGptControllerApi
 * @extends {BaseAPI}
 */
export class BackofficeGptControllerApi extends BaseAPI {
    /**
     * 
     * @param {GPTSearchConditionValue} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeGptControllerApi
     */
    public search1(body: GPTSearchConditionValue, options?: any) {
        return BackofficeGptControllerApiFp(this.configuration).search1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {UpdateGPTCommand} body 
     * @param {string} authorization 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeGptControllerApi
     */
    public updateGPT(body: UpdateGPTCommand, authorization: string, id: string, options?: any) {
        return BackofficeGptControllerApiFp(this.configuration).updateGPT(body, authorization, id, options)(this.fetch, this.basePath);
    }

}
/**
 * BackofficeGptProcessTaskControllerApi - fetch parameter creator
 * @export
 */
export const BackofficeGptProcessTaskControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TriggerProcessGPTCommand} body 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processGPTTasks(body: TriggerProcessGPTCommand, authorization: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling processGPTTasks.');
            }
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling processGPTTasks.');
            }
            const localVarPath = `/backoffice/gpt-process-tasks/process`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TriggerProcessGPTCommand" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GPTProcessTaskSearchConditionValue} body 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGPTProcessTasks(body: GPTProcessTaskSearchConditionValue, authorization: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling searchGPTProcessTasks.');
            }
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling searchGPTProcessTasks.');
            }
            const localVarPath = `/backoffice/gpt-process-tasks/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GPTProcessTaskSearchConditionValue" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BackofficeGptProcessTaskControllerApi - functional programming interface
 * @export
 */
export const BackofficeGptProcessTaskControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TriggerProcessGPTCommand} body 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processGPTTasks(body: TriggerProcessGPTCommand, authorization: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = BackofficeGptProcessTaskControllerApiFetchParamCreator(configuration).processGPTTasks(body, authorization, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {GPTProcessTaskSearchConditionValue} body 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGPTProcessTasks(body: GPTProcessTaskSearchConditionValue, authorization: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageValueGPTProcessTaskValue> {
            const localVarFetchArgs = BackofficeGptProcessTaskControllerApiFetchParamCreator(configuration).searchGPTProcessTasks(body, authorization, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BackofficeGptProcessTaskControllerApi - factory interface
 * @export
 */
export const BackofficeGptProcessTaskControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {TriggerProcessGPTCommand} body 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processGPTTasks(body: TriggerProcessGPTCommand, authorization: string, options?: any) {
            return BackofficeGptProcessTaskControllerApiFp(configuration).processGPTTasks(body, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {GPTProcessTaskSearchConditionValue} body 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGPTProcessTasks(body: GPTProcessTaskSearchConditionValue, authorization: string, options?: any) {
            return BackofficeGptProcessTaskControllerApiFp(configuration).searchGPTProcessTasks(body, authorization, options)(fetch, basePath);
        },
    };
};

/**
 * BackofficeGptProcessTaskControllerApi - object-oriented interface
 * @export
 * @class BackofficeGptProcessTaskControllerApi
 * @extends {BaseAPI}
 */
export class BackofficeGptProcessTaskControllerApi extends BaseAPI {
    /**
     * 
     * @param {TriggerProcessGPTCommand} body 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeGptProcessTaskControllerApi
     */
    public processGPTTasks(body: TriggerProcessGPTCommand, authorization: string, options?: any) {
        return BackofficeGptProcessTaskControllerApiFp(this.configuration).processGPTTasks(body, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {GPTProcessTaskSearchConditionValue} body 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeGptProcessTaskControllerApi
     */
    public searchGPTProcessTasks(body: GPTProcessTaskSearchConditionValue, authorization: string, options?: any) {
        return BackofficeGptProcessTaskControllerApiFp(this.configuration).searchGPTProcessTasks(body, authorization, options)(this.fetch, this.basePath);
    }

}
/**
 * BackofficeRawGptControllerApi - fetch parameter creator
 * @export
 */
export const BackofficeRawGptControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRawGPTCommand} body 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRawGPT(body: CreateRawGPTCommand, authorization: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createRawGPT.');
            }
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling createRawGPT.');
            }
            const localVarPath = `/backoffice/raw-gpts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateRawGPTCommand" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RawGPTSearchCondition} body 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRawGPTs(body: RawGPTSearchCondition, authorization: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling searchRawGPTs.');
            }
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling searchRawGPTs.');
            }
            const localVarPath = `/backoffice/raw-gpts/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RawGPTSearchCondition" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BackofficeRawGptControllerApi - functional programming interface
 * @export
 */
export const BackofficeRawGptControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRawGPTCommand} body 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRawGPT(body: CreateRawGPTCommand, authorization: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = BackofficeRawGptControllerApiFetchParamCreator(configuration).createRawGPT(body, authorization, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {RawGPTSearchCondition} body 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRawGPTs(body: RawGPTSearchCondition, authorization: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageValueRawGPTValue> {
            const localVarFetchArgs = BackofficeRawGptControllerApiFetchParamCreator(configuration).searchRawGPTs(body, authorization, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BackofficeRawGptControllerApi - factory interface
 * @export
 */
export const BackofficeRawGptControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {CreateRawGPTCommand} body 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRawGPT(body: CreateRawGPTCommand, authorization: string, options?: any) {
            return BackofficeRawGptControllerApiFp(configuration).createRawGPT(body, authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {RawGPTSearchCondition} body 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRawGPTs(body: RawGPTSearchCondition, authorization: string, options?: any) {
            return BackofficeRawGptControllerApiFp(configuration).searchRawGPTs(body, authorization, options)(fetch, basePath);
        },
    };
};

/**
 * BackofficeRawGptControllerApi - object-oriented interface
 * @export
 * @class BackofficeRawGptControllerApi
 * @extends {BaseAPI}
 */
export class BackofficeRawGptControllerApi extends BaseAPI {
    /**
     * 
     * @param {CreateRawGPTCommand} body 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeRawGptControllerApi
     */
    public createRawGPT(body: CreateRawGPTCommand, authorization: string, options?: any) {
        return BackofficeRawGptControllerApiFp(this.configuration).createRawGPT(body, authorization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {RawGPTSearchCondition} body 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackofficeRawGptControllerApi
     */
    public searchRawGPTs(body: RawGPTSearchCondition, authorization: string, options?: any) {
        return BackofficeRawGptControllerApiFp(this.configuration).searchRawGPTs(body, authorization, options)(this.fetch, this.basePath);
    }

}
/**
 * GptControllerApi - fetch parameter creator
 * @export
 */
export const GptControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GPTSearchConditionValue} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(body: GPTSearchConditionValue, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling search.');
            }
            const localVarPath = `/gpts/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GPTSearchConditionValue" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upvoteGPT(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling upvoteGPT.');
            }
            const localVarPath = `/gpts/upvote/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GptControllerApi - functional programming interface
 * @export
 */
export const GptControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GPTSearchConditionValue} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(body: GPTSearchConditionValue, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageValueGPTValue> {
            const localVarFetchArgs = GptControllerApiFetchParamCreator(configuration).search(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upvoteGPT(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GptControllerApiFetchParamCreator(configuration).upvoteGPT(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GptControllerApi - factory interface
 * @export
 */
export const GptControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {GPTSearchConditionValue} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(body: GPTSearchConditionValue, options?: any) {
            return GptControllerApiFp(configuration).search(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upvoteGPT(id: string, options?: any) {
            return GptControllerApiFp(configuration).upvoteGPT(id, options)(fetch, basePath);
        },
    };
};

/**
 * GptControllerApi - object-oriented interface
 * @export
 * @class GptControllerApi
 * @extends {BaseAPI}
 */
export class GptControllerApi extends BaseAPI {
    /**
     * 
     * @param {GPTSearchConditionValue} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GptControllerApi
     */
    public search(body: GPTSearchConditionValue, options?: any) {
        return GptControllerApiFp(this.configuration).search(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GptControllerApi
     */
    public upvoteGPT(id: string, options?: any) {
        return GptControllerApiFp(this.configuration).upvoteGPT(id, options)(this.fetch, this.basePath);
    }

}
/**
 * RootControllerApi - fetch parameter creator
 * @export
 */
export const RootControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoot(options: any = {}): FetchArgs {
            const localVarPath = `/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RootControllerApi - functional programming interface
 * @export
 */
export const RootControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoot(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = RootControllerApiFetchParamCreator(configuration).getRoot(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * RootControllerApi - factory interface
 * @export
 */
export const RootControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoot(options?: any) {
            return RootControllerApiFp(configuration).getRoot(options)(fetch, basePath);
        },
    };
};

/**
 * RootControllerApi - object-oriented interface
 * @export
 * @class RootControllerApi
 * @extends {BaseAPI}
 */
export class RootControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RootControllerApi
     */
    public getRoot(options?: any) {
        return RootControllerApiFp(this.configuration).getRoot(options)(this.fetch, this.basePath);
    }

}
