import { useFetchGPTsByCondition } from 'client/SGClient';
import React from 'react';
import { GPTSearchConditionValue } from 'sg-client';

const SidebarFeatured: React.FC = () => {
  const condition: GPTSearchConditionValue = {
    limit: 2,
    offset: 0,
    filter: {
      uuids: [
        'f1b3e2e0-8b1e-11eb-8dcd-0242ac130003',
        'f1b3e2e0-8b1e-11eb-8dcd-0242ac130003',
      ]
    }
  };
  let { gpts, isLoading, reset } = useFetchGPTsByCondition(condition, true);
  
  return (
    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
      {gpts.map((gpt) => (
        <a
          href={`/gpts/${gpt.uuid}`}
          key={gpt.uuid}
          className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
        >
          <div className="flex flex-1 flex-col p-8">
            <img className="mx-auto h-32 w-32 flex-shrink-0 rounded-full" src={gpt.display?.profilePictureUrl} alt="" />
            <h3 className="mt-6 text-sm font-medium text-gray-900">{gpt.display?.name}</h3>
            <dl className="mt-1 flex flex-grow flex-col justify-between">
              <dt className="sr-only">{gpt.display?.description}</dt>
              <dd className="text-sm text-gray-500">{gpt.display?.description}</dd>
              <dt className="sr-only">by {gpt.author?.displayName}</dt>
              <dd className="text-sm text-gray-500">by {gpt.author?.displayName}</dd>
            </dl>
          </div>
        </a>
      ))}
    </ul>
  );
};

export default SidebarFeatured;