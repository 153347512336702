import React, { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

type MenuItem = {
  name: string;
  active?: boolean;
  id: string;
  href: string;
}

const menuItems: MenuItem[] = [
  { name: 'Dashboard', id: 'dashboard', href: '/' },
  { name: 'Categories', id: 'category', href: '/categories' },
  { name: 'Today', id: 'today', href: '/top/today' },
  { name: 'Submit GPT', id: 'submit-gpt', href: 'https://forms.gle/m1MaGJWpwxofKwFs5' },
]

const GPTNavbar: React.FC = () => {

  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <Link className="flex flex-shrink-0 items-center" to={'/'}>
                  <img
                    className="h-16 w-auto"
                    src="/gpthunt.png"
                    alt="GPT Hunt"
                  />
                </Link>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {menuItems.map((item) => (
                    <Link
                      to={item.href}
                      key={item.id}
                      className={`inline-flex items-center border-b-2 border-gptt-600 px-1 pt-1 text-sm font-medium ${window.location.href.endsWith(item.href) ? 'text-gray-900' : 'text-gray-500 border-transparent hover:border-gray-300 hover:text-gray-700'}`}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
              {/* <div className="hidden sm:ml-6 sm:flex sm:items-center">
                <div className="flex-shrink-0">
                  <button
                    type="button"
                    className="relative inline-flex items-center gap-x-1.5 rounded-md bg-gptt-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gptt-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gptt-600"
                  >
                    <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    Submit GPT
                  </button>
                </div>

              </div> */}
              <div className="-mr-2 flex items-center sm:hidden">
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gptt-500">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pb-3 pt-2">
              {menuItems.map((item) => (

                <Disclosure.Button
                  as={Link}
                  key={item.id}
                  to={item.href}
                  className={`block border-l-4 py-2 pl-3 pr-4 text-base font-medium ${window.location.href.endsWith(item.href) ? 'bg-gptt-50 text-gptt-600 border-gptt-500' : 'text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'}`}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
              {/* <Disclosure.Button
                as="a"
                href="submit-gpt"
                className={`block border-l-4 py-2 pl-3 pr-4 text-base font-medium ${window.location.href.endsWith('submit-gpt') ? 'bg-gptt-50 text-gptt-600 border-gptt-500' : 'text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'}`}
              >
                Submit GPT
              </Disclosure.Button> */}
            </div>

          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default GPTNavbar;
