import React, { useState, useEffect } from 'react';
import { GPTSearchConditionValue, GPTValue, OrderByGPT } from 'sg-client';
import { useFetchGPTsByCondition } from 'client/SGClient';
import SectionHeading from 'components/feed/SectionHeading';
import GPTSection from 'components/gpt/GPTSection';
import SidebarCategories from 'components/sidebar/SidebarCategories';

type CategoryFeedProps = {
  category: string;
}

const CategoryFeed: React.FC<CategoryFeedProps> = ({ category }) => {

  const [offset, setOffset] = React.useState(0);

  const condition: GPTSearchConditionValue = {
    limit: 10,
    offset: offset,
    orderBy: {
      orderBy: [OrderByGPT.OrderByEnum.VOTESDESC]
    },
    filter: {
      categories: [category]
    }
  };
  let { gpts, isLoading, totalGPTs } = useFetchGPTsByCondition(condition, true);

  const loadMoreCallback = () => {
    setOffset(offset + 10);
  };

  return (
    <>
      <div className="flex flex-col md:flex-row min-h-screen">
        <div className="main-content md:w-2/3 p-4">
          <div className='mt-4' />
          <SectionHeading title={capitalizeFirstLetter(category)} />
          <GPTSection
            gpts={gpts}
            isLoading={isLoading}
            loadMoreCallback={loadMoreCallback}
            totalGPTs={totalGPTs}
          />
        </div>

        <div className="divider md:hidden w-full bg-gray-300 h-px"></div>

        <div className="divider m-4 hidden md:block md:w-px md:h-auto bg-gray-300"></div>

        <aside className="md:w-1/3 p-4 sidebar">
          <div className='md:sticky md:top-4'>
            <SidebarCategories />
          </div>
        </aside>
      </div>
    </>
  );
};

function capitalizeFirstLetter(string : string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default CategoryFeed;