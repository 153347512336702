import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GPTValue } from "sg-client";


type GPTState = {
  gptList: GPTValue[];
};

const initialState: GPTState = {
  gptList: [],
};

// export const handlePlayThunk = createAsyncThunk(
//   "GPTList/fetchGPTList",
//   async (_, { dispatch, getState }) => {
    
//     const state = getState() as RootState;
//     const { skipHistory } = state.gpt;
//     await dispatch(startSearchBestMove());
//     let gameFen = getFen(position, turnSide);
//     engineManager.startSearch(
//       gameFen,
//       (ply: any) => {
//         // Set the position inside the callback function
//         dispatch(setBestMoveProgress(ply));
//       },
//       (message: any) => {
//         dispatch(finishSearchBestMove());
//       },
//       2000,
//     );
//   },
// );

const gptSlice = createSlice({
  name: "nextMove",
  initialState,
  reducers: {
    setGPTList: (state, action: PayloadAction<GPTValue[]>) => {
      state.gptList = action.payload;
    },
    appendGPTList: (state, action: PayloadAction<GPTValue[]>) => {
      let gptList = action.payload.reduce((acc: GPTValue[], curr: GPTValue) => {
        const duplicate = acc.find(item => item.id === curr.id);
        if (duplicate) {
          return acc.map(item => item.id === curr.id ? curr : item);
        } else {
          return [...acc, curr];
        }
      }, state.gptList);
      gptList = gptList.sort((a, b) => {
        const dateA = a.metadata?.showedDate ? new Date(a.metadata.showedDate) : new Date(0);
        const dateB = b.metadata?.showedDate ? new Date(b.metadata.showedDate) : new Date(0);
        if (dateA.getTime() === dateB.getTime()) {
          return a.id?.localeCompare(b.id || '') || 0;
        }
        return dateB.getTime() - dateA.getTime(); // For descending order
      });

      state.gptList = gptList;
    }
  },
});

export const {
  setGPTList,
  appendGPTList,
} = gptSlice.actions;

export default gptSlice.reducer;