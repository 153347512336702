import moment from "moment";

export function utcDateStart(date: Date) {
  return moment.utc(date).startOf('day').toDate()
}

export function utcNextDayStart(date: Date) {
  return moment.utc(date).add(1, 'days').startOf('day').toDate()
}

export function addDays(date: Date, days: number) {
  return moment(date).add(days, 'days').toDate()
}