import React from 'react';
import store from "../store/Store";
import { Provider } from "react-redux";
import Wrapper from 'Wrapper';
import { useParams } from 'react-router-dom';
import { useFetchGPTsByCondition } from 'client/SGClient';
import GPT from './gpt/GPT';

const GPTPage: React.FC = () => {

  const params = useParams();
  const id = params['*'] || '';

  const { gpts, isLoading, isError } = useFetchGPTsByCondition({
    filter: {
      uuids: [id]
    }
  });

  let gpt = gpts && gpts.length ? gpts[0] : undefined;

  return (
    <>
      <Wrapper>
        <Provider store={store} >
          <div className="min-h-screen">
            {isLoading && (
              <>
                <div className="grid grid-cols-2 mt-4">
                  <div className="justify-self-start md:ml-4 grid grid-cols-auto-2">
                    <div className="h-24 w-24 grid-row-merge-2 skeleton">
                    </div>
                    <div className="self-end ml-5 text-lg skeleton skeleton-text w-64">
                    </div>
                    <div className="self-start ml-5 mt-3 skeleton skeleton-text">
                    </div>
                  </div>
                </div>
                <div className="mt-4 ml-4">
                  <div className='sm:border-b sm:border-gray-900/10 pb-12 sm:pb-4'>
                    <h2 className="text-base font-semibold leading-7 text-gray-900 skeleton skeleton-text"></h2>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600 skeleton skeleton-text">

                    </p>
                  </div>
                </div>
              </>
            )}
            {gpt && !isLoading && (
              <GPT gpt={gpt} />
            )}
          </div>
        </Provider>
      </Wrapper>
    </>
  );
};

export default GPTPage;