import React from 'react';
import store from "../store/Store";
import { Provider } from "react-redux";
import Wrapper from 'Wrapper';

const SubmitGpt: React.FC = () => {
  return (
    <>
      <Wrapper>
        <Provider store={store} >
          <div className="min-h-screen"></div>
        </Provider>
      </Wrapper>
    </>
  );
};

export default SubmitGpt;