export const Categories = [
  "marketing",
  "development",
  "entertainment",
  "psychology",
  "business",
  "health",
  "education",
  "finance",
  "politics",
  "sports",
  "science",
  "technology",
  "art",
  "history",
  "religion",
  "travel",
  "food",
  "fashion",
  "lifestyle",
  "culture",
  "nature",
  "music",
  "movies",
  "books",
  "games",
  "fitness",
  "beauty",
  "parenting",
  "relationships",
  "pets",
  "humor",
  "news",
  "crypto",
  "miscellaneous"
]