import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { GPTValue } from 'sg-client';
import GPT from './GPT';

// open modal based on props
// close modal based on props
type GPTModalProps = {
  open: boolean;
  gpt: GPTValue;
  onClose?: (open: boolean) => void;
}

const GPTModal: React.FC<GPTModalProps> = ({ open, gpt, onClose }: GPTModalProps) => {

  const [openFlag, setOpenFlag] = useState(false)

  React.useEffect(() => {
    setOpenFlag(open);
  }, [open]);

  const onCloseEvent = () => {
    setOpenFlag(false);
    onClose?.(false);
  }

  return (
    <Transition.Root show={openFlag} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCloseEvent}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-full sm:pl-8 sm:mx-8 sm:min-h-screen">
                <div className="absolute right-0 top-0 pr-4 pt-4 block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={() => onCloseEvent()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <GPT gpt={gpt} isShowInModal/>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default GPTModal;