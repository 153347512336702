import React, { useEffect } from 'react';
import { GPTValue } from 'sg-client';
import { format, parse } from 'date-fns';
import { ChevronDoubleUpIcon } from '@heroicons/react/20/solid'
import { upvoteGPT } from 'client/SGClient'
import { Link } from 'react-router-dom';
import GPTModal from './GPTModal';

type SectionLinkProps = {
  href: string;
  title: string;
}

type GPTSectionProps = {
  isLoading: boolean;
  gpts: GPTValue[];
  loadMoreCallback?: () => void;
  hideUpvote?: boolean;
  lockUpvote?: boolean;
  groupGPTByDate?: boolean;
  link?: SectionLinkProps;
  totalGPTs?: number;
}

const GPTSection: React.FC<GPTSectionProps> = ({
  isLoading,
  gpts = [],
  loadMoreCallback,
  hideUpvote = false,
  lockUpvote = false,
  groupGPTByDate = false,
  link = null,
  totalGPTs = undefined
}) => {

  let [isUpvoteLoading, setIsUpvoteLoading] = React.useState(false);

  const handleUpvote = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, gpt: GPTValue, upvoteCallback: () => void) => {
    e.preventDefault();
    if (lockUpvote) {
      return;
    }
    setIsUpvoteLoading(true);
    upvoteGPT(gpt.uuid).then(() => {
      setIsUpvoteLoading(false);
      upvoteCallback();
    });
  }

  if (!gpts && isLoading) {
    return <div>Loading...</div>;
  }

  if (!groupGPTByDate) {
    return (
      <>
        <List gpts={gpts} handleUpvote={handleUpvote} hideUpvote={hideUpvote} isUpvoteLoading={isUpvoteLoading} isLoadingList={isLoading} />
        {(totalGPTs || -1) != gpts.length && (<LoadMore loadMoreCallback={loadMoreCallback} isLoading={isLoading} />)}
        <SectionLink href={link?.href} title={link?.title} />
      </>
    );
  }

  const groupedGptList = groupGPTListByDate(gpts || []);

  return (
    <>
      {groupedGptList.map(([date, gptsDay]) => (
        <div key={date}>
          {groupGPTByDate && (
            <div className="z-10 border-y border-b-gray-200 border-t-gray-100 bg-gray-50 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900">
              <h3>{format(parse(date, 'yyyy-MM-dd', new Date()), 'MMMM d')}</h3>
            </div>)}
          <List gpts={gptsDay} handleUpvote={handleUpvote} hideUpvote={hideUpvote} isUpvoteLoading={isUpvoteLoading} isLoadingList={isLoading} />
        </div>
      ))}
      {((totalGPTs || -1) != gpts.length) && (<LoadMore loadMoreCallback={loadMoreCallback} isLoading={isLoading} />)}
      <SectionLink href={link?.href} title={link?.title} />
    </>
  );
};

type ListProps = {
  gpts: GPTValue[];
  handleUpvote: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, gpt: GPTValue, upvoteCallback: () => void) => void;
  hideUpvote: boolean;
  isUpvoteLoading: boolean;
  isLoadingList: boolean
};
function List({ gpts, handleUpvote, hideUpvote, isUpvoteLoading = false, isLoadingList }: ListProps) {
  let isLoading = isLoadingList && gpts.length === 0;
  return (
    <ul role="list" className="divide-y divide-gray-100">
      {gpts && gpts.map((gpt) => (
        <li key={gpt.id} className="relative py-5 hover:bg-gray-50">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="mx-auto flex max-w-4xl justify-between gap-x-6">
              <div className="flex min-w-0 gap-x-4">
                <img loading="lazy" className="h-12 w-12 flex-none rounded-full bg-gray-50 skeleton" src={gpt.display?.profilePictureUrl} alt="" />
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    <GPTLine gpt={gpt} />
                  </p>
                  <p className="mt-1 flex text-xs leading-5 text-gray-500">
                    <span className="relative truncate">
                      {gpt.metadata?.categories?.map((category) => (
                        <Link key={category} to={`/categories/${category}`} className=" mr-2 hover:underline">
                          {`#${category}`}
                        </Link>
                      ))}
                    </span>
                  </p>
                </div>
              </div>
              <UpvoteCounter gpt={gpt} handleUpvote={handleUpvote} hideUpvote={hideUpvote} isUpvoteLoading={isUpvoteLoading} />
            </div>
          </div>
        </li>
      ))}
      {isLoading && Array.from({ length: 5 }, (_, i) =>
        <li key={i} className="relative py-5 hover:bg-gray-50">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="mx-auto flex max-w-4xl justify-between gap-x-6">
              <div className="flex flex-grow gap-x-4">
                <img className="h-12 w-12 flex-none rounded-full bg-gray-50 skeleton" alt="" />
                <div className="flex-auto gap-x-4">
                  <p className="text-sm font-semibold leading-6 skeleton skeleton-text">
                  </p>
                  <p className="mt-3 flex text-xs leading-5 text-gray-500 skeleton skeleton-text-small">
                  </p>
                </div>
              </div>
            </div>
          </div>
        </li>
      )}
    </ul>)
}

type GPTLineProps = {
  gpt: GPTValue;
}
function GPTLine({ gpt }: GPTLineProps) {

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const onClickEvent = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setIsModalOpen(true);
  }

  return (
    <>
      <a onClick={onClickEvent} className='sm:cursor-default' >
        <span className="absolute inset-x-0 -top-px bottom-0" />
        {gpt.display?.name}
      </a >
      <GPTModal open={isModalOpen} onClose={() => setIsModalOpen(false)} gpt={gpt} />
    </>
  )
  // return (
  //   < Link to={`/gpts/${gpt.uuid}`} className='cursor-default' >
  //     <span className="absolute inset-x-0 -top-px bottom-0" />
  //     {gpt.display?.name}
  //   </Link >
  // )
}

type UpvoteCounterProps = {
  gpt: GPTValue;
  handleUpvote: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, gpt: GPTValue, upvoteCallback: () => void) => void;
  hideUpvote: boolean;
  isUpvoteLoading: boolean;
};

function UpvoteCounter({ gpt, handleUpvote, hideUpvote, isUpvoteLoading = false }: UpvoteCounterProps) {

  const [isAnimating, setIsAnimating] = React.useState(false);
  const [upvoteIncrease, setUpvoteIncrease] = React.useState(0);

  useEffect(() => {
    if (!isAnimating) {
      return;
    }
    // Trigger the animation by adding the class

    // Listen for the end of the animation and remove the class
    const timer = setTimeout(() => {
      setIsAnimating(false);
      setUpvoteIncrease(1);
    }, 2000); // The duration should match your CSS animation

    // Cleanup the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, [isAnimating]); // Depend on the upvotes prop to re-run the effect

  const upvoteCallback = () => {
    setIsAnimating(true);
  }

  const gptUpvotes = gpt.metadata?.upvotes || 0;

  const handleUpvoteEvent = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    if (upvoteIncrease == 0) {
      handleUpvote(e, gpt, upvoteCallback)
    }
  }

  return (
    <a href='#' onClick={(e) => handleUpvoteEvent(e)} className={`hover-trigger ${isUpvoteLoading ? 'sm:cursor-default' : 'sm:cursor-pointer'}`}>
      <div className="flex shrink-0 items-center gap-x-4">
        <dl className="flex w-full flex-none justify-between gap-x-8 sm:w-auto">
          <div className="flex w-6 md:w-8 gap-x-2.5">
            <dt className={`${isAnimating && "absolute animate-up"}`}>
              <div className="relative w-6 h-6 overflow-hidden">
                <ChevronDoubleUpIcon className={`text-gray-500 h-6 w-6 ${!isAnimating && "absolute icon-up"}`} />
                <ChevronDoubleUpIcon className={`text-gray-500 h-6 w-6 ${!isAnimating && "absolute icon-down"}`} />
              </div>
              <div className="relative w-6 h-6">
                <div className={`text-sm h-6 w-6 leading-6 text-gray-900 text-center `}>{hideUpvote ? "-" : gptUpvotes + upvoteIncrease}</div>
              </div>
            </dt>
            {isAnimating && (<dt className={`${isAnimating && "absolute animate-bottom-up"}`}>
              <div className="relative w-6 h-6 overflow-hidden">
                <ChevronDoubleUpIcon className="text-gray-500 h-6 w-6" />
              </div>
              <div className={`text-sm h-6 w-6 leading-6 text-gray-900 text-center`}>{hideUpvote ? "-" : gptUpvotes + 1}</div>
            </dt>)}
          </div>
        </dl>
      </div>
    </a>
  );
}

type LoadMoreProps = {
  loadMoreCallback?: () => void;
  isLoading: boolean;
}

function LoadMore({ loadMoreCallback, isLoading }: LoadMoreProps = { isLoading: false }) {

  const loadMoreEvent = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    loadMoreCallback && loadMoreCallback();
  }

  return (
    <>
      {loadMoreCallback && (<a
        onClick={loadMoreEvent}
        className="flex w-full items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
      >
        {isLoading ? 'Loading...' : "Load More"}
      </a>
      )}
    </>
  );
}

function SectionLink({ href, title }: { href: string | undefined, title: string | undefined }) {
  return (
    <>
      {href && (<Link
        to={href}
        className="flex w-full items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
      >
        {title}
      </Link>
      )}
    </>
  );
}

function groupGPTListByDate(gptList: GPTValue[]) {
  var groupedGptList = gptList.reduce((groups, item) => {
    const date = item.metadata?.showedDate ? format(new Date(item.metadata.showedDate), 'yyyy-MM-dd') : 'No Date';
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(item);
    return groups;
  }, {} as { [key: string]: GPTValue[] });

  return Object.entries(groupedGptList).sort(([dateA], [dateB]) => dateB.localeCompare(dateA));
}

export default GPTSection;